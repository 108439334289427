// Generated by Framer (ddd30d5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BNZynMYZ4", "OH5132oCl"];

const serializationHash = "framer-IPSOl"

const variantClassNames = {BNZynMYZ4: "framer-v-zsw6eg", OH5132oCl: "framer-v-151iy4m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Close: "OH5132oCl", Open: "BNZynMYZ4"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, MATMAFz0o: click ?? props.MATMAFz0o, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BNZynMYZ4"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MATMAFz0o, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BNZynMYZ4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapd7f5y0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (MATMAFz0o) {const res = await MATMAFz0o(...args);
if (res === false) return false;}
setVariant("OH5132oCl")
})

const onTap2sbusk = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (MATMAFz0o) {const res = await MATMAFz0o(...args);
if (res === false) return false;}
setVariant("BNZynMYZ4")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zsw6eg", className, classNames)} data-framer-name={"Open"} data-highlight layoutDependency={layoutDependency} layoutId={"BNZynMYZ4"} onTap={onTapd7f5y0} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({OH5132oCl: {"data-framer-name": "Close", onTap: onTap2sbusk}}, baseVariant, gestureVariant)}><motion.div className={"framer-lsz3pi"} layoutDependency={layoutDependency} layoutId={"IuPrr_Ufs"}><motion.div className={"framer-skj8lx"} layoutDependency={layoutDependency} layoutId={"PKaEho67I"} style={{backgroundColor: "var(--token-aa01f5ac-e7a3-48ec-91e4-9174d5af3cf2, rgb(12, 12, 13))", borderBottomLeftRadius: 298, borderBottomRightRadius: 298, borderTopLeftRadius: 298, borderTopRightRadius: 298, rotate: 0}} variants={{OH5132oCl: {rotate: 45}}}/><motion.div className={"framer-3aboaa"} layoutDependency={layoutDependency} layoutId={"ji65LwjrD"} style={{backgroundColor: "var(--token-aa01f5ac-e7a3-48ec-91e4-9174d5af3cf2, rgb(12, 12, 13))", borderBottomLeftRadius: 298, borderBottomRightRadius: 298, borderTopLeftRadius: 298, borderTopRightRadius: 298, rotate: 0}} variants={{OH5132oCl: {rotate: -45}}}/><motion.div className={"framer-1bsa9lj"} layoutDependency={layoutDependency} layoutId={"FCkluWyem"} style={{backgroundColor: "var(--token-aa01f5ac-e7a3-48ec-91e4-9174d5af3cf2, rgb(12, 12, 13))", borderBottomLeftRadius: 298, borderBottomRightRadius: 298, borderTopLeftRadius: 298, borderTopRightRadius: 298, rotate: 0}} variants={{OH5132oCl: {rotate: 45}}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IPSOl.framer-p7fz14, .framer-IPSOl .framer-p7fz14 { display: block; }", ".framer-IPSOl.framer-zsw6eg { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 54px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 54px; will-change: var(--framer-will-change-override, transform); }", ".framer-IPSOl .framer-lsz3pi { align-content: flex-end; align-items: flex-end; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 3px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-IPSOl .framer-skj8lx, .framer-IPSOl .framer-3aboaa { flex: none; height: 2px; overflow: visible; position: relative; width: 18px; }", ".framer-IPSOl .framer-1bsa9lj { flex: none; height: 2px; overflow: visible; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IPSOl.framer-zsw6eg, .framer-IPSOl .framer-lsz3pi { gap: 0px; } .framer-IPSOl.framer-zsw6eg > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-IPSOl.framer-zsw6eg > :first-child, .framer-IPSOl .framer-lsz3pi > :first-child { margin-top: 0px; } .framer-IPSOl.framer-zsw6eg > :last-child, .framer-IPSOl .framer-lsz3pi > :last-child { margin-bottom: 0px; } .framer-IPSOl .framer-lsz3pi > * { margin: 0px; margin-bottom: calc(3px / 2); margin-top: calc(3px / 2); } }", ".framer-IPSOl.framer-v-151iy4m .framer-skj8lx { left: 0px; position: absolute; right: 0px; top: 0px; width: unset; z-index: 1; }", ".framer-IPSOl.framer-v-151iy4m .framer-1bsa9lj { bottom: 0px; height: unset; left: calc(50.00000000000002% - 10px / 2); position: absolute; top: 0px; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54
 * @framerIntrinsicWidth 54
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"OH5132oCl":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MATMAFz0o":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervIYWB9IQl: React.ComponentType<Props> = withCSS(Component, css, "framer-IPSOl") as typeof Component;
export default FramervIYWB9IQl;

FramervIYWB9IQl.displayName = "Button/Humburger Menu";

FramervIYWB9IQl.defaultProps = {height: 54, width: 54};

addPropertyControls(FramervIYWB9IQl, {variant: {options: ["BNZynMYZ4", "OH5132oCl"], optionTitles: ["Open", "Close"], title: "Variant", type: ControlType.Enum}, MATMAFz0o: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramervIYWB9IQl, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})